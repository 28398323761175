
// main-visual
.main-visual {
    position: relative;
    width: 100%;
}
.main-visual__inner {
    position: relative;
}
.main-visual__img {
    width:100%;
    padding-top: calc(2/3 * 100%);
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
}
.main-visual__con {
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
    width:100%;
    max-width: var(--container);
    color: #fff;
    padding:0 12px;
}
.main-visual__icon{
    width:50px;
    height: 40px;
    background-repeat: no-repeat;
    background-position:center;
    background-size: contain;
    margin-bottom: 6px;
}
.main-visual__tit {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
}
.main-visual__tit + .main-visual__info {
    margin-top: 14px;
}
.main-visual__txt{
    font-size: 1.6rem;
    margin-top: 10px;
}
.main-visual__info > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px -8px;
}
.main-visual__info > ul > li {
    position: relative;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1;
    padding: 4px 8px;
}
.main-visual__info + .main-visual__btn {
    margin-top: 20px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual__img {
        padding-top: calc(714/1920 * 100%);
    }
    .main-visual__icon{
        width:66px;
        height: 52px;
        background-size: auto;
        margin-bottom: 10px;
    }
    .main-visual__tit {
        font-size: 4rem;
    }
    .main-visual__info > ul > li::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, calc(-50% + 1px));
        -ms-transform: translate(0, calc(-50% + 1px));
        transform: translate(0, calc(-50% + 1px));
        display: block;
        width: 1px;
        height: 10px;
        background-color: #fff;
    }
    .main-visual__info > ul > li:first-child::before {
        display: none;
    }
}
@media (min-width: 1024px) {
    .main-visual__img {
        height: 714px;
        padding-top: 0;
    }
}
@media (min-width: 1200px) {
    .main-visual__tit {
        font-size: 5.6rem;
    }
    .main-visual__tit + .main-visual__info {
        margin-top: 26px;
    }
    .main-visual__txt{
        font-size: 2rem;
        margin-top: 20px;
    }
    .main-visual__info > ul {
        margin: -6px -12px;
    }
    .main-visual__info > ul > li {
        font-size: 2rem;
        padding: 6px 12px;
    }
    .main-visual__info + .main-visual__btn {
        margin-top: 56px;
    }
}
@media (min-width: 1380px) {
    .main-visual__con {
        right: 100px;
    }
}

// main-visual swiper
.main-visual .swiper-container{
    height: 100%;
}
.main-visual .swiper-slide{
    background-color: #f1f1f1;
}
.main-visual .swiper-control{
    top: 10px;
    right: 10px;
    left:auto;
    transform: none;
    width:auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding:0;
}
.main-visual .swiper-pagination{
    position: static;
    bottom:auto;
    left:auto;
    transform: none;
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    width:auto;
    font-size: 1.6rem;
    color:var(--v-primary-base);
    margin:0 6px;
}
.main-visual .swiper-pagination .swiper-pagination-current,
.main-visual .swiper-pagination .swiper-pagination-total{
    display: inline-block;
}
.main-visual .swiper-pagination-current{
    color:#fff;
    padding-right: 4px;
}
.main-visual .swiper-pagination-total{
    padding-left: 4px;
}
.main-visual .swiper-prev,
.main-visual .swiper-next{
    position: sticky;
    left:auto;
    right:auto;
    transform: none;
    background-color: rgba(255,255,255,.35);
}
.main-visual .swiper-prev{
    margin-right: 6px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .main-visual .swiper-pagination{
        font-size: 3rem;
        margin:0 16px;
    }
    .main-visual .swiper-pagination-current{
        padding-right: 8px;
    }
    .main-visual .swiper-pagination-total{
        padding-left: 8px;
    }
}
@media (min-width:1380px){
    .main-visual .swiper-control{
        top: 50%;
        left:50%;
        right:auto;
        -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
        width: 100%;
        justify-content: space-between;
        padding:0 12px;
    }
    .main-visual .swiper-prev{
        margin-right: 0;
    }
}
